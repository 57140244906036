import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'

export default () => {
  const siteTitle = get(this, 'props.data.site.siteMetadata.title')
  const formattedTitle = `ISLO - ${siteTitle || 'Home Page'}`
  return (
    <div className="tc white">
        <Helmet title={formattedTitle} >
          <link rel="stylesheet" href="https://unpkg.com/tachyons@4/css/tachyons.min.css" />
        </Helmet>
        <div className='center' style={{ margin: '21% auto' }}>
          <span
            role="img"
            aria-label="Candle"
            style={{ fontSize: '84px', marginRight: '-10px' }}>
            🕯️
          </span>
          <span style={{ fontSize: '64px' }}>slo</span>
        </div>
        <h2>Coming Soon...</h2>
    </div>
  )
}
